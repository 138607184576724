import React from 'react';
import dynamic from 'next/dynamic';

const loading = () => <></>;

export const asyncComponent = loader =>
  dynamic(loader, {
    loading,
    ssr: false,
  });
